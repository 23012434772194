export const paths = {
  index: '/',
  auth: {
    jwt: {
      login: '/auth/jwt/login',
      registration: '/auth/jwt/registration',
      forgotPassword: '/auth/jwt/forgot-password',
      resetPassword: '/auth/jwt/reset-password',
    },
    passwordLess: {
      request: '/auth/password-less/request',
    },
  },
  dashboard: {
    index: '/dashboard/overview',
    account: '/dashboard/account',
    blank: '/dashboard/blank',
    classifieds: {
      index: '/dashboard/classifieds',
      priceChange: '/dashboard/classifieds/price-change',
      sold: '/dashboard/classifieds/sold',
    },
    callTracking: '/dashboard/calls',
    leads: {
      leads: '/dashboard/leads',
      calls: '/dashboard/calls',
      emails: '/dashboard/emails',
    },
    auctions: {
      auctions: '/dashboard/auctions/',
      active: '/dashboard/auctions/active',
      bid: '/dashboard/auctions/bid',
      openbid: '/dashboard/auctions/openbid',
      closedbid: '/dashboard/auctions/closedbid',
      nowinner: '/dashboard/auctions/nowinner',
      won: '/dashboard/auctions/won',
      archived: '/dashboard/auctions/archived',
      favorite: '/dashboard/auctions/favorite',
      messages: '/dashboard/auctions-messages',
      invoices: '/dashboard/auctions-invoices',
    },
  },
  401: '/401',
  404: '/404',
  500: '/500',
  403: '/403',
};
